import React from 'react';
import Typewriter from 'typewriter-effect/dist/core';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Head from '../components/head';
import skills from '../data/skills.json';
import experiences from '../data/experiences.json';
import contactStyles from '../styles/contact.module.scss';
import '../styles/index.scss';

class Index extends React.Component {

  bannerMessageTypewriter;

  constructor() {
    super();
    this.containerEl = null;
    this.contactSection = null;
  }

  componentDidMount() {
    this.bannerMessageTypewriter = new Typewriter(this.containerEl, {loop: false, delay: 75});
    this.bannerMessageTypewriter.typeString('<span>Trust me...</span><br />')
      .pauseFor(300)
      .typeString('<span>I\'m a <span class="dev">web developer</span></span>')
      .start();
  }

  render() {
    return (
      <div>
        <Head />
        <div className={contactStyles.intro}>
          <div>
            <Fade>
              <img src="/assets/bg.jpg" className={contactStyles.bg} />
            </Fade>
          </div>
          <div className={contactStyles.banner}>
            <div className={contactStyles.profiles}>
              <a href="https://www.linkedin.com/in/mpbatungbakal/" target="__blank" rel="noopener noreferrer">
                <img src="/assets/linkedin.svg" />
              </a>
              <a href="https://bitbucket.org/kalamitee/" target="__blank" rel="noopener noreferrer">
                <img src="/assets/bitbucket.svg" />
              </a>
            </div>
            <div>
              <div className={contactStyles.bannerMessage} ref={(el) => {this.containerEl = el}}></div>
              <div className={contactStyles.buttons}>
                <Fade duration={2500}>
                  <button type="button" className={contactStyles.contactButton} onClick={() => this.contactSection.scrollIntoView({behavior: 'smooth', block: 'nearest'})}>Let's work together!</button>
                </Fade>
              </div>
            </div>
          </div>
        </div>
  
        <section className={contactStyles.skillsContainer}>
          <Fade left>
            <div className={contactStyles.skillsDescription}>
              <h1>These skills, <span className={contactStyles.highlight}>at your service</span></h1>
              <p>acquired by working on side project and...</p>
            </div>
          </Fade>
          <div className={contactStyles.skills}>
            {
              skills.skills.map((skill, index) => {
                return (
                  <Bounce delay={index * 80} key={skill.text}>
                    <div className={contactStyles.skill}>
                      <img src={skill.logo} />
                      <div>{skill.text}</div>
                    </div>
                  </Bounce>
                );
              })
            }
          </div>
        </section>
  
        <section className={contactStyles.history}>
          <Fade right>
            <div className={contactStyles.historyHeader}>
              <h1><span className={contactStyles.historyHeaderContent}>...by working with these <span className={contactStyles.highlight}>cool</span> companies</span></h1>
            </div>
          </Fade>
          <VerticalTimeline>
            {
              experiences.companies.map((company) => {
                return (
                  <VerticalTimelineElement
                    key={company.name}
                    className={contactStyles.work}
                    iconClassName='vertical-timeline-element__icon'
                    dateClassName='vertical-timeline-element__date'
                    date={`${company.startDate} - ${company.endDate || 'Present'}`}
                    icon={<img src={company.logo} style={{height: '80%', width: '80%'}} alt={company.name} />}
                    iconOnClick={() => window.open(company.website)}
                  >
                    <div className={contactStyles.name}>{company.name}</div>
                    <div className={contactStyles.position}>{company.position}</div>
                    {
                      company.tools ? 
                      <ul className={contactStyles.tools}>
                        {
                          company.tools.map((tool, index) => <li key={tool}>{tool}</li>)
                        }
                      </ul>
                      : null
                    }
                    <ul className={contactStyles.responsibilities}>
                      {
                        company.responsibilities.map((responsibility, index) => <li key={index} className={contactStyles.responsibility}>{responsibility.description}</li>)
                      }
                    </ul>
                  </VerticalTimelineElement>
                );
              })
            }
            <VerticalTimelineElement
              className={contactStyles.work}
              iconClassName='vertical-timeline-element__icon'
              icon={<img src="/assets/code.svg" style={{height: '80%', width: '80%'}} alt="Coding" />}
              contentStyle={{visibility: 'hidden'}}
            ></VerticalTimelineElement>
          </VerticalTimeline>
        </section>

        <section className={contactStyles.contact} ref={(el) => {this.contactSection = el}}>
          <div className={contactStyles.contactContent}>
            <Fade top>
              <div className={contactStyles.contactMessage}>Allow me to <span className={contactStyles.highlight}>help</span> you...</div>
            </Fade>
            <Fade duration={2500}>
              <form name="contact v1" className={contactStyles.contactForm} method="POST" data-netlify="true" action="/thanks" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact v1" />
                
                <div className={contactStyles.botField}><input name="bot-field" /></div>

                <div className={contactStyles.formGroup}>
                  <label>Name</label>
                  <input type="text" name="name" className={contactStyles.formControl} required />
                </div>

                <div className={contactStyles.formGroup}>
                  <label>Email</label>
                  <input type="email" name="email" className={contactStyles.formControl} required />
                </div>

                <div className={contactStyles.formGroup}>
                  <label>How may I help you?</label>
                  <textarea name="message" className={`${contactStyles.formControl} ${contactStyles.textArea}`} required></textarea>
                </div>

                <button type="submit" className={contactStyles.submit}>Let's talk</button>
              </form>
            </Fade>
          </div>
        </section>
      </div>
    );
  };
}

export default Index;
